.header {
    background-color: #2DABE3;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 15px;
}

.header img {
    max-width: 100px;
}

.header a {
    margin-left: auto;
    cursor: pointer;
}

.header button {
    background-color: white;
    border: none;
    border-radius: 15px;
    padding: 10px 20px;
    cursor: pointer;
}

.header button:hover {
    background-color: rgb(231, 231, 231);
}