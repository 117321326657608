.producto {
    
    border-radius: 0;
    min-width: 95%;
    display: flex;
    padding: 25px;
    margin: 2px;
    text-align: left;
    font-weight: 300;
    background-color: white;
  
}



.categorias-prod {
    font-size: 11px;
    font-weight: 600;
    
    line-height: 14px;
    margin-bottom: 12px;
    
}

.producto img {
    max-width: 120px;
    margin-right: 35px;
    object-fit: cover;
}

.contenedor-productos {
    
    width: 90%;
    max-width: 900px;
    
    margin: auto;
}

.precio-prod {
    margin-top: 12px;
    font-size: 13px;
}

.botones-div {
    margin-top: 20px;
    display: flex;
}
.botones-div button {
border: none;
font-size: 8px;
background-color: rgb(239, 239, 239);
color: black;
text-transform: uppercase;
padding: 7px 15px;
margin-right: 20px;
cursor: pointer;
border-radius: 2px;
font-weight: 500;
}

.botones-div button:hover {
background-color: lightgrey;
}

.botones-div a {
    font-size: 10px;
    cursor: pointer;
    text-decoration: none;
    color: black;
    font-weight: 400;
}

.botones-div a i {
   font-size: 8px;
   margin-left: 5px;
   margin-top: 2px;
}

.botones-div a {
    display: flex;
    align-items: center;
}

.botones-div a:hover {
    font-weight: 600;
}

textarea {
    width: 100%;
    border: none;
    font-size: 11px;
    margin-top: 10px;
    background-color: lightgray;
    padding: 5px;
}

#titulo-div {
    align-items: center;
}

#margin-top-0 {
    margin-top: 0;
}

#titulo-div h2 {
    font-weight: 600;
    margin-right: 50px;
}

@media (max-width: 800px) {
    .producto {
    
     
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
    }
    .producto img {
        max-width: 150px;
        
        margin-bottom: 25px;
    }

    
}