.filtros {
    display: flex;
    width: 900px;
    max-width: 98%;
    margin: auto;
    justify-content: flex-start;
    
    margin-top: 40px;
    margin-bottom: 40px;
    flex-wrap: wrap;
}

.palabra-div {
    text-align: left;
    margin-right: 25px;
}

.palabra-div p {
   
    font-size: 12px;
    margin-bottom: 10px;
    font-weight: 600;
}

.palabra-div input {
    min-height: 30px;
    min-width: 200px;
    border-radius: 0;
    border: none;
    background-color: white;
    padding: 3px;
}

.palabra-div select {
    min-height: 36px;
    min-width: 200px;
    border-radius: 0;
    border: none;
    background-color: white;
    padding: 3px;
}

#corto {
    width: 110px;
    min-width: 100px;
}

@media (max-width: 900px) {

    .palabra-div {
        min-width: 95%;
        margin: auto;
        margin-bottom: 22px;
    }
    .palabra-div input {
        min-width: 88% !important;
    } 
    .palabra-div {
        margin-right: 0;
    }

    .palabra-div select {
        min-width: 90% !important;
        width: 90% !important
    } 

    .palabra-div {
        text-align: left;
        margin-right: 0;
    }

    #corto {
        width: 50% !important;
        min-width: 50% !important;
    }
}


@-moz-document url-prefix() {

    select {
        padding-top: 8px !important;
    }
}