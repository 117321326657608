body {
  margin: 0;
  background-color: rgb(233, 233, 233);

}

code {

}

.carg {
  margin: auto;
  height: 90vh;
  display: flex;
  align-items: center;
}

.carg img {
margin: auto;
}